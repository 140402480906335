import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const ContactUs = (props) => {
  const [opacity, setOpacity] = useState(1);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="contactUs" >
      <div  className="container">
        <div style={{paddingTop:"5%", minHeight:"100vh"}} className="section-title">
          <h2 className="text-center">Contact Us</h2>
          <p>
            You may contact us using the information below: Merchant Legal
            entity name:
          </p>
          <p>
            {" "}
            SOMARASA DISCOVERIES PRIVATE LIMITED Registered Address: WARD NO 48,
            PANCH TOLA, MUSHARI, MUZAFFARPUR, MUZAFFARPUR, BIHAR, 842002,
            AGHORIYA BAZAR, Bihar, PIN: 842002 Operational Address: WARD NO 48,
            PANCH TOLA, MUSHARI, MUZAFFARPUR, MUZAFFARPUR, BIHAR, 842002,
            AGHORIYA BAZAR, Bihar, PIN: 842002 Telephone No: 9234135711 E-Mail
            ID: contact@somarasa.co.in
          </p>
          <div className="col-md-12 policy-link row">
            <a href="/aboutUs">About Us</a>
            <a href="/Refund">Refund Policy</a>
            <a href="/Privacy">Privacy Policy</a>
            <a href="/Shipping">Shipping Policy</a>
            <a href="/termsAndConditions">Terms & Conditions</a>
          </div>
        </div>
      </div>
    </div>
  );
};
