import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const Shipping = (props) => {
  const [opacity, setOpacity] = useState(1);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="shipping">
      <div className="container">
        <div
          style={{ paddingTop: "5%", minHeight: "100vh" }}
          className=" section-title"
        >
          <h2 className="text-center">Shipping Policy</h2>
          <p>
            For International buyers, orders are shipped and delivered through
            registered international courier companies and/or International
            speed post only. For domestic buyers, orders are shipped through
            registered domestic courier companies and /or speed post only.
            Orders are delivered within 15 to 20 days or as per the delivery
            date agreed at the time of order confirmation and delivering of the
            shipment subject to Courier Company / post office norms. SOMARASA
            DISCOVERIES PRIVATE LIMITED is not liable for any delay in delivery
            by the courier company / postal authorities and only guarantees to
            hand over the consignment to the courier company or postal
            authorities within 15 to 20 Days days from the date of the order and
            payment or as per the delivery date agreed at the time of order
            confirmation. Delivery of all orders will be to the address provided
            by the buyer. Delivery of our services will be confirmed on your
            mail ID as specified during registration. For any issues in
            utilizing our services you may contact our helpdesk on
            contact@somarasa.co.in.
          </p>
          <div className="policy-link row">
            <a href="/aboutUs">About Us</a>
            <a href="/Refund">Refund Policy</a>
            <a href="/Privacy">Privacy Policy</a>
            <a href="/Shipping">Shipping Policy</a>
            <a href="/termsAndConditions">Terms & Conditions</a>
          </div>
        </div>
      </div>
    </div>
  );
};
