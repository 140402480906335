import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { shop } from "./shop";
// import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from "./AuthContext";

export const Navigation = (props) => {
  const navigate = useNavigate();
  const navbarRef = useRef(null);
  const dropdownRef = useRef(null);
  const { isAuthenticated, logout } = useAuth();
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const [showNavbar, setShowNavbar] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const lastScrollY = useRef(0);

  const toggleNavbar = () => {
    setNavbarCollapsed((prevState) => !prevState);
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const isActive = (path) => {
    return location.pathname.includes(path);
  };

  const closeNavbarCollapse = () => {
    setNavbarCollapsed(true);
    setShowDropdown(false);
  };

  const handleLogout = () => {
    logout();
    navigate("/login"); // Redirect to the login page
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY.current) {
        setShowNavbar(false); // Scrolling down
      } else {
        setShowNavbar(true); // Scrolling up
      }
      lastScrollY.current = currentScrollY;
    };

    const handleClickOutside = (event) => {
      // Close the navbar or dropdown if a click outside is detected
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicking outside
      }
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setNavbarCollapsed(true);
      }
    };

    const handleResize = () => {
      // Close the navbar collapse on resize to avoid unwanted behavior on screen size change
      setIsMobile(window.innerWidth < 768);
      closeNavbarCollapse();
      setNavbarCollapsed(true);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", handleResize);
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    document.addEventListener("click", handleClickOutside, true);

    // Check on resize
    window.addEventListener("resize", checkMobile);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigateAndScroll = (event, hash) => {
    event.preventDefault();
    if (location.pathname !== "/") {
      // If not on the landing page, navigate there
      window.location.href = `/${hash}`;
    } else {
      // If already on the landing page, just scroll to the section
      document.querySelector(hash).scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleProfileClick = () => {
    if (isAuthenticated) {
      navigate("/account");
    } else {
      navigate("/login");
    }
  };

  return (
    <nav
      ref={navbarRef}
      id="menu"
      className={`navbar navbar-default navbar-fixed-top ${
        showNavbar ? "visible" : "hidden"
      }`}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Navigate through Somarasa's extensive offerings, including spiritual consultations, cultural magazines, handcrafted products, and more. Discover the richness of Indian traditions and spirituality."
        />
        <meta
          name="keywords"
          content="Somarasa navigation, spiritual consultations, cultural magazines, Indian traditions, handcrafted products, heritage preservation, spiritual guidance, cultural heritage, ancient cultures, support heritage"
        />
        <meta name="author" content="Somarasa Discoveries Private Limited" />
        <link rel="canonical" href="https://somarasa.co.in/" />
        <meta property="og:title" content="Somarasa | Navigation" />
        <meta
          property="og:description"
          content="Explore Somarasa's offerings including spiritual consultations, cultural magazines, and handcrafted products. Navigate easily through our diverse sections to immerse in Indian traditions."
        />
        <meta property="og:url" content="https://somarasa.co.in/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="./somarasa full logo red background.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Somarasa | Navigation" />
        <meta
          name="twitter:description"
          content="Navigate Somarasa's offerings, from spiritual guidance to cultural preservation. Discover the depth of Indian traditions and spirituality."
        />
        <meta
          name="twitter:image"
          content="./somarasa full logo red background.webp"
        />
      </Helmet>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            aria-expanded={!navbarCollapsed}
            onClick={toggleNavbar}
          >
            {" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/">
            <img src={require("./somarasa-logo.webp")} alt="logo" />
          </a>{" "}
        </div>

        <div
          className={`collapse navbar-collapse ${!navbarCollapsed ? "in" : ""}`}
        >
          <ul className="nav navbar-nav navbar-right">
            <li className={location.pathname === "/" ? "active" : ""}>
              <Link
                to="/"
                className="page-scroll"
                onClick={closeNavbarCollapse}
              >
                Home
              </Link>
              {/* <a
                href="#header"
                onClick={(e) => handleNavigateAndScroll(e, "#header")}
                className={isActive('/') ? 'active' : ''}
              >
                Home
              </a> */}
            </li>
            <li className={location.pathname === "/magazine" ? "active" : ""}>
              <Link to="/magazine" onClick={closeNavbarCollapse}>
                Magazine
              </Link>
            </li>
            <li className={location.pathname === "/shop" ? "active" : ""}>
              <a
                href="https://thesomastore.com/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeNavbarCollapse}
              >
                Shop
              </a>{" "}
              {/* <a
                href="#magazine"
                onClick={(e) => handleNavigateAndScroll(e, "#magazine")}
                className="page-scroll"
              >
                E-Magazine
              </a> */}
            </li>
            {/* <li
              className={location.pathname === "/consultation" ? "active" : ""}
            >
              <Link to="/consultation">Consult</Link>
            </li> */}

            {!isMobile ? (
              // For larger screens, show dropdown
              <li
                ref={dropdownRef}
                className={`dropdown ${showDropdown ? "open" : ""} ${
                  location.pathname.includes("/consultation") ? "active" : ""
                }`}
              >
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Consult <span className="caret"></span>
                </a>
                {/* <Link
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                to="/consultation"
              >
                Consult <span className="caret"></span>
              </Link> */}
                {/* <a href="#">
                Contact 
              </a> */}
                <ul className={`dropdown-menu ${showDropdown ? "show" : ""}`}>
                  <li>
                    <Link
                      className="text-center"
                      to="/consultation"
                     
                    >
                      Consultancy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-center"
                      to="/consultation/religious-and-ceremonial"
                      
                    >
                      Religious
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-center"
                      to="/consultation/tirth-and-pilgrimage"
                    >
                      Tirth
                    </Link>
                  </li>
                </ul>
              </li>
            ) : (
              // For small screens, show all options directly
              <>
                <li>
                  <Link to="/consultation" onClick={closeNavbarCollapse}>
                    Consultancy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/consultation/religious-and-ceremonial"
                    onClick={closeNavbarCollapse}
                  >
                    Religious
                  </Link>
                </li>
                <li>
                  <Link
                    to="/consultation/tirth-and-pilgrimage"
                    onClick={closeNavbarCollapse}
                  >
                    Tirth
                  </Link>
                </li>
              </>
            )}

            {/* <li className={location.pathname === "/supportPage" ? "active" : ""}>
              <Link to="/supportPage">Support Us</Link>
            </li> */}
            <li className={location.pathname === "/blog" ? "active" : ""}>
              <Link to="/blog" onClick={closeNavbarCollapse}>
                Blog
              </Link>
            </li>

            <li className={location.pathname === "/contact" ? "active" : ""}>
              <Link to="/contact" onClick={closeNavbarCollapse}>
                Contact Us
              </Link>
            </li>
            {/* <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Contact <span className="caret"></span>
              </a>
              <ul className="dropdown-menu">
              <li>
                  <Link to="/aboutUs">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/Refund">Refund Policy</Link>
                </li>
                <li>
                  <Link to="/Privacy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/Shipping">Shipping Policy</Link>
                </li>
                <li>
                  <Link to="/termsAndConditions">Terms & Conditions</Link>
                </li>
              </ul>
            </li> */}
            {/* <li>
              <a
                href="#contact"
                onClick={(e) => handleNavigateAndScroll(e, "#contact")}
                className="page-scroll"
              >
                Contact
              </a>
            </li> */}
            {/* {isAuthenticated ? (
              <li>
                <Link to="/account">My Account</Link>
              </li>
            ) : (
              <li>
                <a
                  href="#"
                  onClick={() => navigate("/login")}
                >
                  My Account
                </a>
              </li>
            )} */}

            {/* <li>
              <a onClick={handleProfileClick} className="page-scroll">
                My Profile
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};
