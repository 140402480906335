import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const Refund = (props) => {
  const [opacity, setOpacity] = useState(1);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="refund">
      <div className="container">
        <div style={{paddingTop:"5%", minHeight:"100vh"}} className="section-title">
          <h2 className="text-center">Refund Policy</h2>
          <p>
            SOMARASA DISCOVERIES PRIVATE LIMITED believes in helping its customers as far as possible, 
            and has therefore a liberal cancellation policy. Under this policy:
          </p>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
            <li>
              Cancellations will be considered only if the request is made immediately after placing the order. 
              However, the cancellation request may not be entertained if the orders have been communicated to the 
              vendors/merchants and they have initiated the process of shipping them.
            </li>
            <li>
              SOMARASA DISCOVERIES PRIVATE LIMITED does not accept cancellation requests for 
              perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer 
              establishes that the quality of product delivered is not good.
            </li>
            <li>
              In case of receipt of damaged or defective items please report the same to our Customer Service team. 
              The request will, however, be entertained once the merchant has checked and determined the same at his 
              own end. This should be reported within 15 days of receipt of the products.
            </li>
            <li>
              In case you feel that the product received is not as shown on the site or as per your expectations, you 
              must bring it to the notice of our customer service within 15 days of receiving the product. The Customer 
              Service Team after looking into your complaint will take an appropriate decision.
            </li>
            <li>
              In case of complaints regarding products that come with a warranty from manufacturers, please refer 
              the issue to them.
            </li>
            <li>
              In case of any Refunds approved by SOMARASA DISCOVERIES PRIVATE LIMITED, it’ll take 6-8 
              days for the refund to be processed to the end customer.
            </li>
          </ul>
          <div className="col-md-12 policy-link row">
            <a href="/aboutUs">About Us</a>
            <a href="/Refund">Refund Policy</a>
            <a href="/Privacy">Privacy Policy</a>
            <a href="/Shipping">Shipping Policy</a>
            <a href="/termsAndConditions">Terms & Conditions</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refund;
