import React, { useEffect, useRef } from "react";

const ShopifyProduct = ({ productId }) => {
  const productRef = useRef(null);

  useEffect(() => {
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
    const script = document.createElement("script");
    script.async = true;
    script.src = scriptURL;
    document.head.appendChild(script);

    const applyCustomStyles = () => {
      // Apply styles to hide the first image only if it is not nested deeper
      const style = document.createElement("style");
      style.textContent = `
        .shopify-buy__product-img-wrapper > img:first-of-type {
          display: none !important;
        }
      `;
      document.head.appendChild(style);
    };

    script.onload = () => {
      if (
        window.ShopifyBuy &&
        typeof window.ShopifyBuy.buildClient === "function"
      ) {
        const client = window.ShopifyBuy.buildClient({
          domain: "83bb36-43.myshopify.com",
          storefrontAccessToken: "d7eda0950fe6b24373e7e7549645ad2f",
        });

        window.ShopifyBuy.UI?.onReady(client)
          .then((ui) => {
            ui.createComponent("product", {
              id: productId,
              node: productRef.current,
              moneyFormat: "Rs.%20%7B%7Bamount%7D%7D",
              options: {
                product: {
                  buttonDestination: "cart", //cart for directly adding in part and modal for dsiplaying popup of product
                  contents: {
                    img: false,
                    imgWithCarousel: false,
                    title: true,
                    price: true,
                    options: false,
                    quantity: true,
                    button: false,
                  },
                  styles: {
                    product: {
                      "text-align": "center",
                      "@media (min-width: 601px)": {
                        "max-width": "calc(100% - 20px)",
                        "margin-left": "10px",
                        "margin-bottom": "10px",
                      },
                    },
                    title: { color: "#ffffff" },
                    price: {
                      color: "#d0acac",
                      "font-size": "1.5rem",
                      "font-weight": "bold",
                    },
                    compareAt: {
                      color: "#e7d4d4",
                      opacity: "0.6",
                      "font-size": "1rem",
                      "font-weight": "bold",
                    },
                    options: {
                      select: {
                        "font-size": "16px",
                        color: "#ffffff",
                        "background-color": "#444444",
                      },
                      option: {
                        color: "#ffffff !important",
                      },
                      label: {
                        color: "#ffffff !important",
                      },
                    },
                    button: {
                      "background-color": "#6e0507",
                      "background-image":
                        "linear-gradient(to right, rgba(255, 4, 0, 0.8) 0%, rgba(255, 162, 0, 0.8) 100%)",
                      color: "#fff",
                      ":hover": {
                        "background-color": "#bb090c",
                        "background-image":
                          "linear-gradient(to right, #bb090c, #bb090c)",
                      },
                      ":focus": {
                        "background-color": "#bb090c",
                      },
                      "border-radius": "25px",
                      padding: "14px 34px",
                      "font-family": "Raleway, sans-serif",
                      "font-weight": "500",
                      "font-size": "15px",
                      "text-transform": "uppercase",
                    },
                    quantityInput: {
                      display: "none",
                    },
                  },
                  text: {
                    button: "Add to cart",
                  },
                },
                modalProduct: {
                  contents: {
                    img: false,
                    imgWithCarousel: true,
                    button: false,
                  },
                  styles: {
                    product: {
                      "background-color": "#e27201",
                      "@media (min-width: 601px)": {
                        "max-width": "100%",
                        "margin-left": "0px",
                        "margin-bottom": "0px",
                      },
                    },
                    button: {
                      ":hover": {
                        "background-color": "#bb090c",
                      },
                      "background-color": "#6e0507",
                      ":focus": {
                        "background-color": "#bb090c",
                      },
                    },
                  },
                  text: {
                    button: "Add to cart",
                  },
                },
                cart: {
                  styles: {
                    button: {
                      ":hover": {
                        "background-color": "#bb090c",
                      },
                      "background-color": "#6e0507",
                      ":focus": {
                        "background-color": "#bb090c",
                      },
                      "border-radius": "27px",
                    },
                  },
                  text: {
                    total: "Subtotal",
                    button: "Checkout",
                  },
                },
                toggle: {
                  styles: {
                    toggle: {
                      "background-color": "#e27d01",
                      ":hover": {
                        "background-color": "#cb7101",
                      },
                      ":focus": {
                        "background-color": "#cb7101",
                      },
                    },
                    count: {
                      color: "#6e0507",
                      ":hover": {
                        color: "#6e0507",
                      },
                    },
                    iconPath: {
                      fill: "#6e0507",
                    },
                  },
                },
                modal: {
                  styles: {
                    modal: {
                      "background-color": "#e27201",
                    },
                  },
                },
              },
            })
              .then(() => {
                // Watch for changes in the component container

                applyCustomStyles(); // Initial application of styles
                // Set up a mutation observer to reapply styles when DOM changes
                const observer = new MutationObserver(applyCustomStyles);
                observer.observe(productRef.current, {
                  childList: true,
                  subtree: true,
                });
              })
              .catch((error) => {
                console.error("Error during component creation:", error);
              });
          })
          .catch((error) => {
            console.error("Error on ready:", error);
          });
      } else {
        console.error("ShopifyBuy.UI is not properly initialized.");
      }
    };

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [productId]);

  return <div ref={productRef} id={`product-component-${productId}`}></div>;
};

function setupNavigationArrows(images) {
  const navigationContainer =
    document.querySelector(".shopify-buy__product") || document.body;
  const leftArrow = document.createElement("button");
  const rightArrow = document.createElement("button");
  leftArrow.innerHTML = "&lt;";
  rightArrow.innerHTML = "&gt;";
  leftArrow.onclick = () => navigateImages(images, -1);
  rightArrow.onclick = () => navigateImages(images, 1);
  navigationContainer.appendChild(leftArrow);
  navigationContainer.appendChild(rightArrow);
}

function navigateImages(images, direction) {
  const currentIndex = Array.from(images).findIndex(
    (img) => img.style.display === "block"
  );
  const nextIndex = currentIndex + direction;
  if (nextIndex >= 0 && nextIndex < images.length) {
    images[currentIndex].style.display = "none";
    images[nextIndex].style.display = "block";
  }
}

export default ShopifyProduct;

//   const productRef = useRef(null);

//   useEffect(() => {
//     const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
//     let script = document.createElement('script');
//     script.async = true;
//     script.src = scriptURL;
//     document.head.appendChild(script);

//     script.onload = () => {
//       if (window.ShopifyBuy) {
//         if (typeof window.ShopifyBuy.buildClient === 'function') {
//           ShopifyBuyInit();
//         } else {
//           console.error('ShopifyBuy.buildClient is not a function.');
//         }
//       } else {
//         console.error('ShopifyBuy is not available on the window object.');
//       }
//     };

//     function ShopifyBuyInit() {
//       const client = window.ShopifyBuy.buildClient({
//         domain: '556480.myshopify.com',
//         storefrontAccessToken: '02042fe9087dc362686f7074d295afa9',
//       });

//       window.ShopifyBuy.UI.onReady(client).then((ui) => {
//         ui.createComponent('product', {
//           id: productId,
//           node: productRef.current,
//           moneyFormat: 'Rs.%20%7B%7Bamount%7D%7D',
//           options: {
//             product: {
//               buttonDestination: 'modal',
//               contents: {
//                 img: true,
//                 title: true,
//                 price: true,
//                 options: true,
//                 quantity: true,
//                 button: true,
//               },
//               styles: {
//                 title: {
//                   color: "#ffffff"
//                 },
//                 price: {
//                   color: "#ffffff"
//                 },
//                 compareAt: {
//                   color: "#ff6347"
//                 },
//                 options: { // Ensuring dropdown styles are enforced
//                   select: {
//                     "font-size": "16px",
//                     color: "#ffffff", // Setting text color white
//                     "background-color": "#444444" // Darker background for contrast
//                   },
//                   option: {
//                     color: "#ffffff" // Ensuring option text in dropdown is white
//                   },
//                   selectWrapper: {
//                     backgroundColor: "#444444",
//                     color: "#ffffff",
//                   }
//                 },
//                 button: {
//                   "background-color": "#6e0507",
//                   "background-image":
//                     "linear-gradient(to right, rgba(255, 4, 0, 0.8) 0%, rgba(255, 162, 0, 0.8) 100%)",
//                   color: "#fff",
//                   ":hover": {
//                     "background-color": "#bb090c",
//                     "background-image":
//                     "linear-gradient(to right, #bb090c, #bb090c)",
//                   },
//                   ":focus": {
//                     "background-color": "#bb090c",
//                   },
//                   "border-radius": "25px",
//                   padding: "14px 34px",
//                   "font-family": "Raleway, sans-serif",
//                   "font-weight": "500",
//                   "font-size": "15px",
//                   "text-transform": "uppercase",
//                 },
//                 quantityInput: {
//                   display: "none",
//                 },
//               },
//               text: {
//                 button: 'View Product',
//               }
//             },
//             cart: {
//               styles: {
//                 button: {
//                   "background-color": "#e27602",
//                   ":hover": {
//                     "background-color": "#bb090c",
//                   },
//                   ":focus": {
//                     "background-color": "#bb090c",
//                   }
//                 }
//               },
//               text: {
//                 total: 'Subtotal',
//                 button: 'Checkout'
//               }
//             },
//             toggle: {
//               styles: {
//                 toggle: {
//                   "background-color": "#e27602",
//                   ":hover": {
//                     "background-color": "#bb090c",
//                   },
//                   ":focus": {
//                     "background-color": "#bb090c",
//                   }
//                 }
//               }
//             }
//           }
//         });
//       });
//     }

//     return () => {
//       if (script.parentNode) {
//         script.parentNode.removeChild(script);
//       }
//     };
//   }, [productId]); // Ensure the dependency array is correct

//   return <div ref={productRef} id={`product-component-${productId}`}></div>;
// };

// export default ShopifyProduct;
