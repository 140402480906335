import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import loadingImage from "./somarasa logo png.webp";
import { Contact } from "./contact";
import ShopifyProduct from "./ShopifyProduct";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const TirthPilgrimage = (props) => {
  const [opacity, setOpacity] = useState(1);

  const headerData = props.data?.header;
  const contactInfo = props.data?.Contact;
  const hero = props.data?.hero;
  const services = props.data?.services || [];
  const totalServices = services.length;
  const faqs = props.data?.faqs;
  const carouselImages = props.data?.carouselImages || [];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(0);
  const [modalContent, setModalContent] = useState(null);

  const handleReadMore = (service) => {
    setModalContent(service);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    document.getElementById("menu").classList.remove("hidden");
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  const handleImageLoad = () => {
    setLoadedImages((prev) => {
      return prev + 1;
    });
  };

  const [openedDropdown, setOpenedDropdown] = useState(null);

  const toggleDropdown = (index) => {
    if (openedDropdown === index) {
      setOpenedDropdown(null); // Close dropdown
    } else {
      setOpenedDropdown(index); // Open dropdown
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (totalServices > 0 && loadedImages === totalServices) {
      setIsLoading(false); // Hide spinner
    } else if (totalServices === 0) {
      setIsLoading(false); // Hide spinner even if no services
    }
  }, [loadedImages, totalServices]);

  return (
    <div id="TirthPilgrimage">
      <Helmet>
        <title>Tirth and Pilgrimage Consultations | Somarasa</title>
        <meta
          name="description"
          content="Plan your sacred journeys with Somarasa. Explore Varanasi, Prayagraj, Ayodhya, and more with tailored itineraries and spiritual guidance."
        />
        <meta
          name="keywords"
          content="Tirth Pilgrimage, Varanasi Spiritual Tours, Prayagraj Rituals, Ayodhya Sacred Ceremonies, Spiritual Guidance"
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is included in the pilgrimage consultation?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our consultations include itinerary planning, pooja arrangements, spiritual guides, and accommodation options."
            }
          },
          {
            "@type": "Question",
            "name": "Can I book ceremonies in advance?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, we assist in advance bookings for ceremonies at major pilgrimage sites."
            }
          }
        ]
      }
    `}
        </script>
      </Helmet>

      {/* <div id="blog">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Somarasa Blog | Cultural Heritage & Spiritual Practices</title>
          <meta
            name="description"
            content="Explore Somarasa's blog for insights on cultural heritage, spiritual practices, and ancient traditions. Discover stories that connect you with India's rich history and spirituality."
          />
          <meta
            name="keywords"
            content="Somarasa blog, cultural heritage, spiritual practices, ancient traditions, Indian culture, spiritual insights, heritage articles, cultural stories, spiritual knowledge, global traditions"
          />
          <meta name="author" content="Somarasa Discoveries Private Limited" />
          <link rel="canonical" href="https://somarasa.co.in/blog" />
          <meta
            property="og:title"
            content="Somarasa Blog | Cultural Heritage & Spiritual Practices"
          />
          <meta
            property="og:description"
            content="Read insightful articles on cultural heritage, spiritual practices, and ancient traditions. Connect with India's rich history and spirituality through Somarasa's blog."
          />
          <meta property="og:url" content="https://somarasa.co.in/blog" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="./somarasa full logo red background.webp"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Somarasa Blog | Cultural Heritage & Spiritual Practices"
          />
          <meta
            name="twitter:description"
            content="Discover insights on cultural heritage, spiritual practices, and ancient traditions in Somarasa's blog. Explore stories that connect you with India's rich history."
          />
          <meta
            name="twitter:image"
            content="./somarasa full logo red background.webp"
          />
        </Helmet>
        <header id="header" style={{ opacity }}>
          <div className="intro">
            <div className="overlay">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 col-md-offset-2 intro-text">
                    <h1>
                      {headerData ? headerData.title : "Loading"}
                      <span></span>
                    </h1>
                    <p style={{ marginBottom: "60px" }}>
                      {headerData ? headerData.paragraph2 : "Loading"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div> */}

      {/* Hero Section */}
      <div id="religious">
        <section className="hero" style={{ opacity }}>
          {hero ? (
            <div className="row">
              <div className="hero-content">
                <h1>{hero.title}</h1>
                <p>{hero.description}</p>
                <div id="consultancy">
                <a 
                  href={hero.buttonLink}
                  className="btn btn-custom btn-lg page-scroll"
                >
                  {hero.buttonText}
                </a>
                </div>
              </div>
            </div>
          ) : (
            <p>Loading hero content...</p>
          )}
        </section>
      </div>

      {/* Services Section */}
      <div id="religious">
        <section id="services" className="services">
          <div className="container">
            <h2>Our Offerings</h2>

            <div className="services-grid">
              {services.map((service) => (
                <div key={service.id} className="service-card">
                  <div className="service-image-wrapper">
                    <img
                      src={service.image}
                      alt={service.title}
                      className="service-image"
                      onLoad={handleImageLoad}
                    />
                    <div className="service-overlay">
                      <p>{service.description}</p>
                      <button
                        className="read-more-btn"
                        onClick={() => handleReadMore(service)}
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="service-button">
                    <ShopifyProduct productId={service.productId} />
                  </div>
                  <div id="consultancy" style={{ padding: "0" }}>
                    <a
                      href={service.productLink}
                      className="btn btn-custom"
                      style={{ margin: "0" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {isModalOpen && modalContent && (
          <div className="modal-overlay">
            <div className="modal">
              <div
                className="modal-background"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${modalContent.image
                    .replace(/ /g, "%20")
                    .trim()})`,
                }}
              ></div>
              <div className="modal-content">
                <button className="close-btn" onClick={closeModal}>
                  &times;
                </button>
                <div className="modal-text">
                  <h3>{modalContent.title}</h3>
                  <p>{modalContent.description}</p>
                  <h4 style={{ color: "orange" }}>What You Will Receive</h4>
                  {modalContent.whatYouWillReceive.map((item) => (
                    <p key={item.label}>
                      <span style={{ color: "orange" }}>{item.label}:</span>{" "}
                      {item.value}
                    </p>
                  ))}
                  <h4 style={{ color: "orange" }}>Why Book This ?</h4>
                  <p>{modalContent.whyBookThis}</p>
                  <div className="modal-button">
                    <ShopifyProduct productId={modalContent.productId} />
                  </div>
                  <div id="consultancy" style={{ padding: "0" }}>
                    <a
                      href={modalContent.productLink}
                      className="btn btn-custom"
                      style={{ margin: "0" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {carouselImages.length > 0 && (
        <div id="service-carousel" className="carousel-container">
          <h2>Manifest your Tirth</h2>
          <Slider {...sliderSettings}>
            {carouselImages.map((image) => (
              <div key={image.id} className="carousel-slide">
                <img
                  src={image.url}
                  alt={image.alt}
                  className="carousel-image"
                />
              </div>
            ))}
          </Slider>
        </div>
      )}

      {/* FAQ Section */}
      <div id="donation" className="faqs">
        <div id="donationImpact" className="container">
          <h2>Frequently Asked Questions</h2>
          <ul>
            {faqs ? (
              faqs.map((item, index) => (
                <li key={index} onClick={() => toggleDropdown(index)}>
                  {item.title}
                  {openedDropdown === index && (
                    <div className="dropdown-content">{item.details}</div>
                  )}
                </li>
              ))
            ) : (
              <p>Loading faqs data...</p>
            )}
          </ul>
        </div>
      </div>

      <Contact data={contactInfo} />
    </div>
  );
};

export default TirthPilgrimage;

{
  /* Why Choose Us Section */
}
{
  /* <section className="why-choose-us">
        <div className="container">
          <h2>Why Choose Us?</h2>
          <ul>
            <li>
              <strong>Expert Planning:</strong> Experience unmatched expertise
              in planning sacred journeys.
            </li>
            <li>
              <strong>Authentic Rituals:</strong> Organized in adherence to
              ancient traditions.
            </li>
            <li>
              <strong>Comfort and Ease:</strong> Seamless travel and stay
              arrangements.
            </li>
          </ul>
        </div>
      </section> */
}

{
  /* Testimonials Section */
}
{
  /* <section className="testimonials">
        <div className="container">
          <h2>What Our Customers Say</h2>
          <div className="testimonial">
            <p>
              "A deeply spiritual experience made simple and memorable thanks to
              Somarasa."
            </p>
            <p className="author">- Arjun Mehta</p>
          </div>
          <div className="testimonial">
            <p>
              "All arrangements were perfect, and the guides were knowledgeable
              and kind."
            </p>
            <p className="author">- Sneha Gupta</p>
          </div>
        </div>
      </section> */
}
