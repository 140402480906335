// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import ShopifyBuyButton from "./ShopifyButton";
// import Shopify from "./Shopify";
// import { Contact } from "./contact";
// import loadingLogo from "./somarasa logo png.webp";
// import axios from "axios";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// export const Shop = (props) => {
//   const [opacity, setOpacity] = useState(1);
//   const location = useLocation();
//   const [currentItemIndex, setCurrentItemIndex] = useState(0);

//   const [tshirtProduct, setTshirtProduct] = useState([]);
//   const [specialProduct, setspecialProduct] = useState([]);
//   const [mugProduct, setMugProduct] = useState([]);
//   const [bagProduct, setBagProduct] = useState([]);

//   const [tshirtLoading, setTshirtLoading] = useState(true);
//   const [specialLoading, setspecialLoading] = useState(true);
//   const [mugLoading, setMugLoading] = useState(true);
//   const [bagLoading, setBagLoading] = useState(true);

//   const [b2bProducts, setB2bProducts] = useState([]);
//   const [b2bLoading, setB2bLoading] = useState(true);

//   const handleScroll = () => {
//     const scrollY = window.scrollY;
//     const newOpacity = Math.max(1 - scrollY / 500, 0); // Adjust the denominator to control the fade speed
//     setOpacity(newOpacity);
//   };

//   const calculateDiscountPercentage = (originalPrice, currentPrice) => {
//     const discount = originalPrice - currentPrice;
//     return Math.round((discount / originalPrice) * 100);
//   };

//   useEffect(() => {
//     const fetchProducts = async () => {
//       setTimeout(() => {
//         setTshirtProduct([...Array(18).keys()].map((id) => ({ id: { id } })));
//         setspecialProduct([...Array(6).keys()].map((id) => ({ id: { id } })));
//         setMugProduct([...Array(5).keys()].map((id) => ({ id: { id } })));
//         setBagProduct([...Array(3).keys()].map((id) => ({ id: { id } })));
//         setTshirtLoading(false);
//         setspecialLoading(false);
//         setMugLoading(false);
//         setBagLoading(false);
//       }, 3000);
//     };

//     const fetchB2BProducts = async () => {
//       try {
//         const response = await axios.get(
//           "https://somarasa-back.onrender.com/api/b2-b-products?populate=image"
//         );
//         console.log("B2B Products Response:", response.data);
//         if (response.data && response.data.data) {
//           setB2bProducts(response.data.data);
//         } else {
//           console.error("Data not formatted as expected:", response.data);
//         }
//         setB2bLoading(false);
//       } catch (error) {
//         console.error("Error fetching B2B products:", error);
//         setB2bLoading(false);
//       }
//     };

//     fetchProducts();
//     fetchB2BProducts();

//     window.addEventListener("scroll", handleScroll);

//     const scrollToElement = () => {
//       const hash = location.hash.replace("#", ""); // Remove the '#' from the hash
//       if (hash) {
//         const element = document.getElementById(hash);
//         if (element) {
//           element.scrollIntoView({ behavior: "smooth", block: "start" });
//         }
//       }
//     };

//     window.scrollTo(0, 0);
//     scrollToElement(); // Call the function to scroll to the element

//     window.addEventListener("hashchange", scrollToElement, { passive: true });
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//       window.removeEventListener("hashchange", scrollToElement);
//     };
//   }, [location]);

//   const [showPopup, setShowPopup] = useState(false);
//   const [formData, setFormData] = useState({ name: "", email: "" });

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const response = await axios.post(
//         "http://somarasa-back.onrender.com/api/b2b-cilent-datas",
//         {
//           data: formData,
//         }
//       );
//       console.log("Form data submitted:", response.data);

//       // Create a link and trigger download
//       const link = document.createElement("a");
//       link.href =
//         "https://drive.google.com/uc?export=download&id=1ynf3vkMmD7JtOJFy4uP1zMO5qqrD2iqK";
//       link.setAttribute("download", "filename.pdf"); // Optional: Provide a default filename for the download
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       setFormData({ name: "", email: "" });
//       setShowPopup(false); // Close the popup
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//   };

//   const headerData = props.data?.header;
//   const contactInfo = props.data?.Contact;
//   const ImagesItem = props.data?.ImagesItem || [];
//   const dharmaKitContent = props.data?.DharmaKitContent || [];
//   const dharmaKit = props.data?.DharmaKit;

//   const tshirtProducts = [
//     { id: "7457109246161" },
//     { id: "7457107968209" },
//     { id: "7457107312849" },
//     { id: "7457108230353" },
//     { id: "7457108787409" },
//     { id: "7457111081169" },
//     { id: "7457110098129" },
//     { id: "7457110196433" },
//     { id: "7457110917329" },
//     { id: "7457110393041" },
//     { id: "7457110491345" },
//     { id: "7457110622417" },
//     { id: "7457110950097" },
//     { id: "7457108426961" },
//     { id: "7457108951249" },
//     { id: "7457107640529" },
//     { id: "7457109475537" },
//     { id: "7457109606609" },
//   ];

//   const special = [
//     { id: "7461961171153" },
//     { id: "7459068215505" },
//     { id: "7459076964561" },
//     { id: "7458994913489" },
//     { id: "7460248223953" },
//     { id: "7459089711313" },
//     { id: "7459097542865" },
//   ];
//   const mugProducts = [
//     { id: "7457109639377" },
//     { id: "7457109770449" },
//     { id: "7457109901521" },
//     { id: "7457109967057" },
//     { id: "7457109803217" },
//   ];

//   const bagProducts = [
//     { id: "7457111802065" },
//     { id: "7457111933137" },
//     { id: "7457112031441" },
//   ];

//   const goToPreviousItem = () => {
//     setCurrentItemIndex((prev) =>
//       prev === 0 ? props.data.ImagesItem.length - 1 : prev - 1
//     );
//   };

//   const goToNextItem = () => {
//     setCurrentItemIndex((prev) =>
//       prev === props.data.ImagesItem.length - 1 ? 0 : prev + 1
//     );
//   };

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true, // Ensure arrows are enabled
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           arrows: true, // Adjust based on your preference
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           arrows: false, // You can disable arrows here if they are too intrusive
//         },
//       },
//     ],
//   };

//   return (
//     <div id="shop">
//       <Helmet>
//         <title>Somarasa Shop</title>
//         <meta charSet="utf-8" />
//         <meta
//           name="description"
//           content="Shop unique cultural products at Somarasa. Discover a wide range of handcrafted items, spiritual kits, and traditional arts that celebrate Indian heritage and spirituality. Support local artisans and preserve ancient traditions with our curated collections"
//         />
//         <meta
//           name="keyword"
//           content="Somarasa shop, cultural products, handcrafted items, spiritual kits, Indian heritage, traditional arts, cultural preservation, unique gifts, Hindu culture, artisan crafts, global traditions."
//         />
//         <link rel="canonical" href="https://somarasa.co.in/contact" />
//       </Helmet>

//       <header id="header" style={{ opacity }}>
//         <div className="intro">
//           <div className="overlay">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-8 col-md-offset-2 intro-text">
//                   <h1>
//                     {headerData ? headerData.title : "Loading"}
//                     <span></span>
//                   </h1>
//                   <p style={{ marginBottom: "60px" }}>
//                     {headerData ? headerData.paragraph2 : "Loading"}
//                   </p>
//                   <a
//                     href="#consultancy"
//                     className="btn btn-custom btn-lg page-scroll"
//                   >
//                     Explore
//                   </a>{" "}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//       <div id="consultancy" className="text-center">
//         <div id="shop-item" className="container">
//           <div className="container">
//             <div className="section-title">
//               <h3>Explore Our COLLECTION!</h3>

//               <div className="navigation-boxes">
//                 <a href="#tshirts" className="navigation-box t-shirts">
//                   <span> Explore T-shirts</span>
//                 </a>
//                 <a href="#mugs" className="navigation-box mugs">
//                   <span> Explore Mugs</span>
//                 </a>
//                 <a href="#bag" className="navigation-box bags">
//                   <span> Explore Bags</span>
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div id="special tshirts" className="container shop shop-special">
//             <div className="section-title">
//               <h2 className="text-center">Special Collection</h2>
//               {specialLoading ? (
//                 <div className="loading-container">
//                   <img
//                     src={loadingLogo}
//                     alt="Loading..."
//                     className="loading-logo"
//                   />
//                   <p>Loading...</p>
//                 </div>
//               ) : (
//                 <div className="row">
//                   {special.map((product, index) => (
//                     <div className="col-sm-12 col-md-4" key={index}>
//                       <div className="shopify-product">
//                         <div className="thumbnail ">
//                           <div className="shopify-btn">
//                             <Shopify productId={product.id}>
//                               View Product
//                             </Shopify>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>
//           </div>
//           <div id="tshirts" className="container shop shop-tshirts">
//             <div className="section-title">
//               <h2 className="text-center">Our T-SHIRT</h2>
//               {tshirtLoading ? (
//                 <div className="loading-container">
//                   <img
//                     src={loadingLogo}
//                     alt="Loading..."
//                     className="loading-logo"
//                   />
//                   <p>Loading...</p>
//                 </div>
//               ) : (
//                 <div className="row">
//                   {tshirtProducts.map((product, index) => (
//                     <div className="col-sm-12 col-md-4" key={index}>
//                       <div className="shopify-product">
//                         <div className="thumbnail ">
//                           <div className="shopify-btn">
//                             <Shopify productId={product.id}>
//                               View Product
//                             </Shopify>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>
//           </div>
//           <div id="mugs" className="container shop shop-tshirts">
//             <div className="section-title">
//               <h2>Our MUGS</h2>
//               {mugLoading ? (
//                 <div className="loading-container">
//                   <img
//                     src={loadingLogo}
//                     alt="Loading..."
//                     className="loading-logo"
//                   />
//                   <p>Loading...</p>
//                 </div>
//               ) : (
//                 <div className="row">
//                   {mugProducts.map((product, index) => (
//                     <div className="col-sm-12 col-md-4" key={index}>
//                       <div className="shopify-product">
//                         <div className="thumbnail ">
//                           <div className="shopify-btn">
//                             <Shopify productId={product.id}>
//                               View Product
//                             </Shopify>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>
//           </div>
//           <div id="bag" className="container shop shop-tshirts">
//             <div className="section-title">
//               <h2>Our BAGS</h2>
//               {bagLoading ? (
//                 <div className="loading-container">
//                   <img
//                     src={loadingLogo}
//                     alt="Loading..."
//                     className="loading-logo"
//                   />
//                   <p>Loading...</p>
//                 </div>
//               ) : (
//                 <div className="row">
//                   {bagProducts.map((product, index) => (
//                     <div className="col-sm-12 col-md-4" key={index}>
//                       <div className="shopify-product">
//                         <div className="thumbnail ">
//                           <div className="shopify-btn">
//                             <Shopify productId={product.id}>
//                               View Product
//                             </Shopify>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>
//           </div>
//           {/* <div className="section-title">
//             <div id="kit" className="shop shop-kit">
//               <div className="container">
//                 <h2>Our DHARMA KIT!</h2>
//                 <div className="row">
//                   <div className="col-sm-12 col-md-6">
//                     <div className="img-container">
//                       {ImagesItem.length > 0 && (
//                         <div className="slider-container">
//                           <button
//                             onClick={goToPreviousItem}
//                             className="slider-btn prev-btn"
//                           >
//                             &lt;
//                           </button>
//                           <img
//                             src={ImagesItem[currentItemIndex]}
//                             className="styled-image"
//                             alt={`Slide ${currentItemIndex}`}
//                           />
//                           <button
//                             onClick={goToNextItem}
//                             className="slider-btn next-btn"
//                           >
//                             &gt;
//                           </button>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                   <div className="col-sm-12 col-md-6">
//                     <div className="text-container">
//                       <p className="styled-paragraph">
//                         <div
//                           id="magazineDescription"
//                           className="dharma-kit-container"
//                         >
//                           <p className="styled-paragraph">
//                             Introducing the{" "}
//                             <span class="highlight">
//                               {" "}
//                               Dharma Kit by Somarasa!
//                             </span>
//                           </p>
//                           <p className="styled-paragraph">
//                             Step into a world where every choice reflects your
//                             deepest values. This kit is a heartfelt invitation
//                             to integrate the ancient wisdom of Dharma into your
//                             daily life.
//                           </p>

//                           <ul className="text-center">
//                             <li>
//                               <span className="highlight">
//                                 Complete Set item including:
//                               </span>{" "}
//                             </li>
//                             <li>Perfume Set and Incense sticks</li>
//                             <li>An idol and Ekmukhi Rudraksha,</li>
//                             <li>Jute bag and recycled notebooks,</li>
//                             <li>Cultural e-magazine.</li>
//                           </ul>

//                           <p>
//                             <span className="highlight">Purchase now! </span>{" "}
//                             Enrich your life with meaningful traditions.
//                           </p>
//                         </div>
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="pricing-container">
//                   {dharmaKit && (
//                     <div>
//                       <h4 style={{ marginTop: "0" }}>{dharmaKit.title}</h4>
//                       <p
//                         style={{
//                           paddingTop: "0",
//                           marginBottom: "5px",
//                           textTransform: "uppercase",
//                         }}
//                       ></p>
//                       <div className="price-section">
//                         <span className="current-price">
//                           ₹{dharmaKit.currentPrice}
//                         </span>
//                         <span className="original-price">
//                           MRP ₹{dharmaKit.originalPrice}
//                         </span>
//                         <span className="discount">
//                           (
//                           {calculateDiscountPercentage(
//                             dharmaKit.originalPrice,
//                             dharmaKit.currentPrice
//                           )}
//                           % OFF)
//                         </span>
//                       </div>
//                       <div className="tax-info" style={{ padding: "1%" }}>
//                         exclusive of all taxes
//                       </div>
//                       <div className="shopify-btn">
//                         <ShopifyBuyButton productId="7457112195281">
//                           Pre Order
//                         </ShopifyBuyButton>
//                       </div>{" "}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div> */}
//           <div id="b2b" className="container shop shop-b2b">
//             <div className="section-title">
//               <h2>Business-to-Business Products</h2>
//               <Slider {...sliderSettings}>
//                 {b2bProducts.length > 0 ? (
//                   b2bProducts.map((product, index) => (
//                     <div key={index} className="slider-item">
//                       {product.attributes.image.data ? (
//                         <img
//                           src={product.attributes.image.data[0].attributes.url}
//                           alt={`Product ${index + 1}`}
//                           style={{ width: "100%", height: "auto" }}
//                         />
//                       ) : (
//                         <p>No image available</p>
//                       )}
//                     </div>
//                   ))
//                 ) : (
//                   <p>No products found.</p>
//                 )}
//               </Slider>
//               <div
//                 className="btn btn-custom-red btn-custom"
//                 style={{ marginTop: "25px" }}
//                 onClick={() => setShowPopup(true)}
//               >
//                 Download PDF
//               </div>
//             </div>
//           </div>
//           {showPopup && (
//             <div className="submission-popup">
//               <div className="popup-content">
//                 <div
//                   className="close-btn"
//                   onClick={() => setShowPopup(false)}

//                 >
//                   &times;
//                 </div>{" "}
//                 <form onSubmit={handleSubmit}>
//                   <input
//                     type="text"
//                     id="name"
//                     name="name"
//                     placeholder="Name"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                     required
//                   />
//                   <input
//                     type="email"
//                     id="email"
//                     name="email"
//                     placeholder="Email"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     required
//                   />
//                   <button type="submit" className="btn btn-custom-red btn-custom">
//                     Submit and Download
//                   </button>
//                 </form>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Contact data={contactInfo} />
//     </div>
//   );
// };
// export default Shop;

// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import { Contact } from "./contact";
// import axios from "axios";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Collection from "./Collection";
// import loadingImage from "./somarasa logo png.webp";

// export const Shop = (props) => {
//   const [opacity, setOpacity] = useState(1);
//   const location = useLocation();
//   const [currentItemIndex, setCurrentItemIndex] = useState(0);
//   const [activeTab, setActiveTab] = useState("tshirts");
//   const [loadingTabContent, setLoadingTabContent] = useState(false);

//   const [b2bProducts, setB2bProducts] = useState([]);
//   const [b2bLoading, setB2bLoading] = useState(true);
//   const [tshirtLoading1, setTshirtLoading1] = useState(true);
//   const [tshirtLoading2, setTshirtLoading2] = useState(true);
//   const [showSecondTshirtCollection, setShowSecondTshirtCollection] =
//     useState(false);
//   const [mugLoading, setMugLoading] = useState(true);
//   const [bagLoading, setBagLoading] = useState(true);

//   const handleScroll = () => {
//     const scrollY = window.scrollY;
//     const newOpacity = Math.max(1 - scrollY / 500, 0);
//     setOpacity(newOpacity);
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);

//     const scrollToElement = () => {
//       const hash = location.hash.replace("#", "");
//       if (hash) {
//         const element = document.getElementById(hash);
//         if (element) {
//           element.scrollIntoView({ behavior: "smooth" });
//         }
//       }
//     };

//     window.scrollTo(0, 0);
//     scrollToElement(); // Call the function to scroll to the element

//     window.addEventListener("hashchange", scrollToElement, { passive: true });

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//       window.removeEventListener("hashchange", scrollToElement);
//     };
//   }, [location]);

//   const fetchB2BProducts = async () => {
//     try {
//       const response = await axios.get(
//         "https://somarasa-back.onrender.com/api/b2-b-products?populate=image"
//       );
//       if (response.data && response.data.data) {
//         setB2bProducts(response.data.data);
//       }
//       setB2bLoading(false);
//     } catch (error) {
//       console.error("Error fetching B2B products:", error);
//       setB2bLoading(false);
//     }
//   };
//   const loadMoreTshirts = () => {
//     setTshirtLoading2(true); // Set loading state for second collection
//     setShowSecondTshirtCollection(true);
//     setTimeout(() => setTshirtLoading2(false), 2000); // Simulate fetching T-shirts
//   };

//   const handleTabChange = (tab) => {
//     setLoadingTabContent(true);
//     setActiveTab(tab);
//     if (tab === "tshirts") {
//       setTshirtLoading1(true);
//       setShowSecondTshirtCollection(false);
//     } else if (tab === "mugs") {
//       setMugLoading(true);
//     } else if (tab === "bags") {
//       setBagLoading(true);
//     }
//     setTimeout(() => {
//       setLoadingTabContent(false);
//       if (tab === "tshirts") {
//         setTshirtLoading1(false);
//       } else if (tab === "mugs") {
//         setMugLoading(false);
//       } else if (tab === "bags") {
//         setBagLoading(false);
//       }
//     }, 3000); // Simulate loading for 2 seconds
//   };

//   useEffect(() => {
//     fetchB2BProducts();
//     setTimeout(() => setTshirtLoading1(false), 3000); // Simulate fetching T-shirts
//     setTimeout(() => setMugLoading(false), 3000); // Simulate fetching Mugs
//     setTimeout(() => setBagLoading(false), 3000); // Simulate fetching Bags

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const [showPopup, setShowPopup] = useState(false);
//   const [formData, setFormData] = useState({ name: "", email: "" });

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const response = await axios.post(
//         "https://somarasa-back.onrender.com/api/b2b-cilent-datas",
//         {
//           data: formData,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       const link = document.createElement("a");
//       link.href =
//         "https://drive.google.com/uc?export=download&id=1ynf3vkMmD7JtOJFy4uP1zMO5qqrD2iqK";
//       link.setAttribute("download", "filename.pdf");
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       setFormData({ name: "", email: "" });
//       setShowPopup(false);
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//   };

//   const headerData = props.data?.header;
//   const contactInfo = props.data?.Contact;

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           arrows: true,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           arrows: false,
//         },
//       },
//     ],
//   };

//   return (
//     <div id="shop">
//       <Helmet>
//         <title>Somarasa Shop</title>
//         <meta charSet="utf-8" />
//         <meta
//           name="description"
//           content="Shop unique cultural products at Somarasa. Discover a wide range of handcrafted items, spiritual kits, and traditional arts that celebrate Indian heritage and spirituality. Support local artisans and preserve ancient traditions with our curated collections"
//         />
//         <meta
//           name="keyword"
//           content="Somarasa shop,Soma Rasa, cultural products, handcrafted items, spiritual kits, Indian heritage, traditional arts, cultural preservation, unique gifts, Hindu culture, artisan crafts, global traditions."
//         />
//         <link rel="canonical" href="https://somarasa.co.in/contact" />
//       </Helmet>

//       <header id="header" style={{ opacity }}>
//         <div className="intro">
//           <div className="overlay">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-8 col-md-offset-2 intro-text">
//                   <h1>
//                     {headerData ? headerData.title : "Loading"}
//                     <span></span>
//                   </h1>
//                   <p style={{ marginBottom: "60px" }}>
//                     {headerData ? headerData.paragraph2 : "Loading"}
//                   </p>
//                   <a
//                     href="#consultancy"
//                     className="btn btn-custom btn-lg page-scroll"
//                   >
//                     Explore
//                   </a>{" "}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>

//       <div id="consultancy" className="text-center">
//         <div id="shop-item" className="container">
//           <div className="section-title">
//             <h3>Explore Our COLLECTION!</h3>
//            </div>

//           <div id="b2b" className="container shop shop-b2b">
//             <div className="section-title">
//               <h2>Business-to-Business Products</h2>
//               <Slider {...sliderSettings}>
//                 {b2bProducts.length > 0 ? (
//                   b2bProducts.map((product, index) => (
//                     <div key={index} className="slider-item">
//                       {product.attributes.image.data ? (
//                         <img
//                           src={product.attributes.image.data[0].attributes.url}
//                           alt={`Product ${index + 1}`}
//                           style={{ width: "100%", height: "auto" }}
//                         />
//                       ) : (
//                         <p>No image available</p>
//                       )}
//                     </div>
//                   ))
//                 ) : (
//                   <p>No products found.</p>
//                 )}
//               </Slider>
//               <div
//                 className="btn btn-custom-red btn-custom"
//                 style={{ marginTop: "25px" }}
//                 onClick={() => setShowPopup(true)}
//               >
//                 Download PDF
//               </div>
//             </div>
//           </div>

//           {showPopup && (
//             <div className="submission-popup">
//               <div className="popup-content">
//                 <div className="close-btn" onClick={() => setShowPopup(false)}>
//                   &times;
//                 </div>{" "}
//                 <form onSubmit={handleSubmit}>
//                   <input
//                     type="text"
//                     id="name"
//                     name="name"
//                     placeholder="Name"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                     required
//                   />
//                   <input
//                     type="email"
//                     id="email"
//                     name="email"
//                     placeholder="Email"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     required
//                   />
//                   <button
//                     type="submit"
//                     className="btn btn-custom-red btn-custom"
//                   >
//                     Submit and Download
//                   </button>
//                 </form>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Contact data={contactInfo} />
//     </div>
//   );
// };

// export default Shop;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import loadingImage from "./somarasa logo png.webp";
import { Contact } from "./contact";

export const Shop = (props) => {
  const [opacity, setOpacity] = useState(1);
  const location = useLocation();

  // State for collections
  const [collections, setCollections] = useState([]);
  const [loadingCollections, setLoadingCollections] = useState(true);

  // State for B2B products
  const [b2bProducts, setB2bProducts] = useState([]);
  const [b2bLoading, setB2bLoading] = useState(true);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const scrollToElement = () => {
      const hash = location.hash.replace("#", "");
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    window.scrollTo(0, 0);
    scrollToElement(); // Call the function to scroll to the element

    window.addEventListener("hashchange", scrollToElement, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("hashchange", scrollToElement);
    };
  }, [location]);

  // Fetch Collections from Strapi
  const fetchCollections = async () => {
    try {
      const response = await axios.get(
        "https://somarasa-back.onrender.com/api/collections?populate=image"
      );
      console.log("API Response:", response.data); // Log the entire response
      if (response.data && response.data.data) {
        setCollections(response.data.data);
      }
      setLoadingCollections(false);
    } catch (error) {
      console.error("Error fetching collections:", error);
      setLoadingCollections(false);
    }
  };

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    console.log("Collections State:", collections); // Log the collections state
  }, [collections]);

  // Fetch B2B Products from Strapi
  const fetchB2BProducts = async () => {
    try {
      const response = await axios.get(
        "https://somarasa-back.onrender.com/api/b2-b-products?populate=image"
      );
      if (response.data && response.data.data) {
        setB2bProducts(response.data.data);
      }
      setB2bLoading(false);
    } catch (error) {
      console.error("Error fetching B2B products:", error);
      setB2bLoading(false);
    }
  };

  useEffect(() => {
    // fetchCollections();
    fetchB2BProducts();
  }, []);

  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "" });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://somarasa-back.onrender.com/api/b2b-cilent-datas",
        {
          data: formData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const link = document.createElement("a");
      link.href =
        "https://drive.google.com/uc?export=download&id=1ynf3vkMmD7JtOJFy4uP1zMO5qqrD2iqK";
      link.setAttribute("download", "filename.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setFormData({ name: "", email: "" });
      setShowPopup(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const headerData = props.data?.header;
  const contactInfo = props.data?.Contact;

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div id="shop">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Somarasa Shop | Cultural Products & Traditional Arts | Handcrafted
          Items
        </title>
        <meta
          name="description"
          content="Shop unique cultural products at Somarasa. Discover handcrafted items, spiritual kits, and traditional arts celebrating Indian heritage and spirituality. Support local artisans and preserve ancient traditions with our curated collections."
        />
        <meta
          name="keywords"
          content="Somarasa shop, cultural products, handcrafted items, spiritual kits, Indian heritage, traditional arts, cultural preservation, unique gifts, Hindu culture, artisan crafts, global traditions, B2B products, business-to-business, curated collections"
        />
        <meta name="author" content="Somarasa Discoveries Private Limited" />
        <link rel="canonical" href="https://somarasa.co.in/shop" />
        <meta
          property="og:title"
          content="Somarasa Shop | Cultural Products & Traditional Arts | Handcrafted Items"
        />
        <meta
          property="og:description"
          content="Explore Somarasa's shop for unique cultural products, handcrafted items, and spiritual kits that celebrate Indian heritage. Support local artisans and preserve ancient traditions."
        />
        <meta property="og:url" content="https://somarasa.co.in/shop" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="./somarasa full logo red background.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Somarasa Shop | Cultural Products & Traditional Arts | Handcrafted Items"
        />
        <meta
          name="twitter:description"
          content="Discover unique cultural products, handcrafted items, and spiritual kits at Somarasa. Our shop celebrates Indian heritage and spirituality. Support local artisans with our curated collections."
        />
        <meta
          name="twitter:image"
          content="./somarasa full logo red background.webp"
        />
      </Helmet>

      <header id="header" style={{ opacity }}>
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <h1>
                    {headerData ? headerData.title : "Loading"}
                    <span></span>
                  </h1>
                  <p style={{ marginBottom: "60px" }}>
                    {headerData ? headerData.paragraph2 : "Loading"}
                  </p>
                  <a
                    href="#consultancy"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Explore
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div id="consultancy" className="text-center">
        <div id="shop-item" className="container">
          <div className="section-title">
            <h3>Explore Our COLLECTION!</h3>
          </div>

          {/* Collection Fetching from Strapi */}
          <div className="section-title">
            {loadingCollections ? (
              <div className="loading-container">
                <img src={loadingImage} alt="Loading..." />
                <p>Loading...</p>
              </div>
            ) : (
              <div id="shop">
                <div className="container shopify-collection">
                  {collections.map((collection, index) => (
                    <a
                      href="https://thesomastore.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                    >
                      <div
                        id="shop-item"
                        key={index}
                        className={`row collection-row ${
                          index % 2 === 0 ? "row-left-image" : "row-right-image"
                        }`}
                      >
                        <div className="col-md-6 image-container">
                          {collection.attributes?.image?.data ? (
                            <img
                              src={
                                collection.attributes.image.data.attributes
                                  .formats?.large?.url ||
                                collection.attributes.image.data.attributes
                                  .formats?.medium?.url ||
                                collection.attributes.image.data.attributes.url
                              }
                              alt={
                                collection.attributes.title ||
                                "No title available"
                              }
                              className="collection-image img-responsive"
                            />
                          ) : (
                            <img
                              src={loadingImage}
                              alt="Image not available"
                              className="collection-image img-responsive"
                            />
                          )}
                        </div>
                        <div className="col-md-6 text-container">
                          <h4 className="collection-title">
                            {collection.attributes?.title ||
                              "No title available"}
                          </h4>
                          <p className="collection-description">
                            {collection.attributes?.description ||
                              "No description available"}
                          </p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div id="b2b" className="container shop shop-b2b">
            <div className="section-title">
              <h2>Business-to-Business Products</h2>
              <Slider {...sliderSettings}>
                {b2bProducts.length > 0 ? (
                  b2bProducts.map((product, index) => (
                    <div key={index} className="slider-item">
                      {product.attributes.image.data ? (
                        <img
                          src={product.attributes.image.data[0].attributes.url}
                          alt={`Product ${index + 1}`}
                          style={{ width: "100%", height: "auto" }}
                        />
                      ) : (
                        <p>No image available</p>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No products found.</p>
                )}
              </Slider>
              <div
                className="btn btn-custom-red btn-custom"
                style={{ marginTop: "25px" }}
                onClick={() => setShowPopup(true)}
              >
                Download PDF
              </div>
            </div>
          </div>

          {showPopup && (
            <div className="submission-popup">
              <div className="popup-content">
                <div className="close-btn" onClick={() => setShowPopup(false)}>
                  &times;
                </div>{" "}
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <button
                    type="submit"
                    className="btn btn-custom-red btn-custom"
                  >
                    Submit and Download
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <Contact data={contactInfo} />
    </div>
  );
};

export default Shop;
