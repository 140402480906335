import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Contact } from "./contact";
import ShopifyBuyButton from "./ShopifyButton";

export const DharmaConsultancy = (props) => {
  const [opacity, setOpacity] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedSaptvargi, setIsExpandedSaptvargi] = useState(false);
  const [isExpandedSpiritual, setIsExpandedSpiritual] = useState(false);
  const [openedDropdown, setOpenedDropdown] = useState(null);

  const contact = props.data?.Contact;
  const faqs = props.data?.faqs;
  const whyForYou = props.data?.whyForYou;

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDropdown = (index) => {
    if (openedDropdown === index) {
      setOpenedDropdown(null); // Close dropdown
    } else {
      setOpenedDropdown(index); // Open dropdown
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    question: "",
  });

  // Destructuring props within the function body
  const { headerData, dharmaServices, contactInfo } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitData = {
      data: formData,
    };
    try {
      const response = await fetch(
        "https://somarasa-back.onrender.com/api/surveys",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submitData),
        }
      );
      const responseData = await response.json();
      console.log("Submission Success:", responseData);
      // Reset form or handle success
    } catch (error) {
      console.error("Submission Error:", error);
    }
  };

  return (
    <div id="dharma">
      
      <div id="magazine" className="text-center">
        <div id="aboutUs" className="container">
          <div className="section-title" style={{ paddingTop: "60px" }}>
            <h2>What is Dharma? </h2>
            <div className="about-us">
              <div className="styled-paragraph ">
                <p style={{ padding: "0" }}>
                  Dharma is the foundation of life, the path that guides one
                  towards righteousness, purpose, and moral conduct. It’s not
                  just a set of rules but a way of living that aligns with
                  universal principles and values. Dharma is the compass that
                  directs every action towards harmony and balance in life. At
                  Somarasa, we understand that navigating this path requires
                  guidance rooted in ancient wisdom and modern understanding.
                </p>
                <p>
                  Dharma Consultancy is your stepping stone to reconnect with
                  the core principles that elevate life. Through our
                  consultation, we help individuals find clarity, peace, and
                  direction, aligning their lives with Dharma. Our consultations
                  are designed to cater to personal needs, ensuring that each
                  individual finds their path and purpose in life through
                  ancient Vedic knowledge.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="consultation-page">
        
        <div
          id="consultancy"
          className="services text-center"
          style={{ padding: "0" }}
        >
          <div className="container">
            <div className="section-title text-center">
              <h2>What We Offer</h2>
              <div className="styled-paragraph">
                <p>
                  At Somarasa, we offer specialized services under our Dharma
                  Consultancy to help you connect with the sacred aspects of
                  life and spirituality. We believe that everyone’s spiritual
                  needs are unique, and our offerings reflect this diversity.
                </p>
              </div>
            </div>

            
            

            {/* Saptvargi Kundali Section */}
            <div className="section sampoorna-kundali-section">
              <div className="align-items-center">
                <h2>Saptvargi Kundali</h2>
                <div className="col-md-6" style={{ padding: "0" }}>
                  <img
                    src={require("./Janm patrika.webp")}
                    alt="Sampoorna Kundali Illustration"
                    className="illustration-image"
                  />
                </div>
                <div className="col-md-6 text-block">
                  <h3>Unveil the Secrets of Your Cosmic Blueprint</h3>
                  <p>
                    The Saptvargi Kundali is a comprehensive astrological
                    analysis that delves deep into every aspect of your life.
                    Crafted by the revered Jyotisha Acharyas of Kashi, this
                    Kundali is more than just a birth chart; it’s a complete
                    roadmap of your cosmic journey, designed to provide profound
                    insights into your past, present, and future. Whether you’re
                    looking to understand your true purpose or navigate the
                    complexities of life, the Saptvargi Kundali offers a
                    timeless guide to your spiritual and worldly path.
                  </p>
                </div>
                <ul style={{ color: "#807171" }}>
                  Disclaimer: The actual product may vary slightly in color,
                  design, or appearance from the image shown due to lighting,
                  manufacturing variations, or other factors
                </ul>
                <div>
                  <h4>What Will You Get in This?</h4>
                  <ul className="styled-list">
                    {isExpandedSaptvargi ? (
                      <>
                        <li>
                          A Complete Kundali in booklet format, meticulously
                          created by the Acharyas of Kashi, offering detailed
                          insights into every aspect of your astrological chart.
                        </li>
                        <li>
                          A Customized Spiritual Kit tailored specifically for
                          you, based on your Kundali. This kit will contain
                          items and tools to help you grow spiritually and
                          attract abundance in your life.
                        </li>
                        <li>
                          A One-on-One Call with the Acharya to help you fully
                          understand your Kundali. During this personalized
                          consultation, you’ll gain deeper knowledge about your
                          chart and how to align with cosmic forces guiding you.
                        </li>
                      </>
                    ) : (
                      <li>
                        A Complete Kundali in booklet format, meticulously
                        created by the Acharyas of Kashi.
                      </li>
                    )}
                  </ul>
                  <p
                    onClick={() => setIsExpandedSaptvargi(!isExpandedSaptvargi)}
                    style={{
                      padding: "0",
                      margin: "0",
                      cursor: "pointer",
                      color: "#ffff00",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    {isExpandedSaptvargi ? "Read Less" : "Read More"}
                  </p>
                  <div className="price-section">
                    <span className="current-price">₹5,500</span>
                    <span className="original-price">MRP ₹8,500</span>
                    <span className="discount">(35% OFF)</span>
                  </div>
                  <div className="tax-info">exclusive of all taxes</div>
                  <p style={{ textAlign: "center", paddingBottom: "0" }}>
                    Delivery Time: 1 month
                  </p>
                  <div className="shopify-btn">
                    <ShopifyBuyButton productId="9641700262204" />
                  </div>{" "}
                </div>
              </div>
            </div>

            {/* Spiritual Guidance Section */}
            <div className="section spiritual-guidance-section">
              <div className="row align-items-center">
                <h2>Spiritual Guidance</h2>
                <div className="col-md-6" style={{ padding: "0" }}>
                  <img
                    src={require("./Sadhu bg removed.webp")}
                    alt="Spiritual Guidance Illustration"
                    className="illustration-image"
                  />
                </div>
                <div className="col-md-6 text-block">
                  <div></div>
                  <h3>
                    Transform Your Life with Personalized Spiritual Support
                  </h3>
                  <p>
                    Our Spiritual Guidance service is a transformative offering
                    designed to provide personalized support on your spiritual
                    journey. Whether you’re seeking clarity, inner peace, or
                    deeper self-awareness, this service offers one-on-one
                    mentorship to help you align with your higher self and
                    navigate life’s challenges. Perfect for individuals who wish
                    to enhance their spiritual practice, find balance, and
                    cultivate mindfulness in their daily lives.
                  </p>
                </div>
                <div>
                  <h4>What Will You Get in This?</h4>
                  <ul className="styled-list">
                    {isExpandedSpiritual ? (
                      <>
                        <li>
                          Personalized Spiritual Support tailored to your unique
                          path and needs, focusing on helping you cultivate
                          inner peace, mindfulness, and spiritual growth.
                        </li>
                        <li>
                          One-on-One Mentorship with our experienced mentor,
                          providing direct guidance and support to help you
                          overcome life's obstacles and connect with your true
                          purpose.
                        </li>
                        <li>
                          Tools for Spiritual Growth, including practical
                          exercises, meditative practices, and personalized
                          insights to enhance your journey toward enlightenment
                          and inner peace.
                        </li>
                      </>
                    ) : (
                      <li>
                        A Complete Kundali in booklet format, meticulously
                        created by the Acharyas of Kashi.
                      </li>
                    )}
                  </ul>
                 
                  <p
                    onClick={() => setIsExpandedSpiritual(!isExpandedSpiritual)}
                    style={{
                      padding: "0",
                      margin: "0",
                      cursor: "pointer",
                      color: "#ffff00",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    {isExpandedSpiritual ? "Read Less" : "Read More"}
                  </p>
                  <div className="price-section">
                    <span className="current-price">Starting: ₹1,000</span>
                    <span className="original-price">MRP ₹1,800</span>
                    <span className="discount">(44% OFF)</span>
                  </div>
                  <div className="tax-info">exclusive of all taxes</div>
                  <ul style={{ color: "#807171" }}>
                    Please select a different time range and add it to your cart
                    to check the price for each duration. The price will be
                    displayed in your cart after clicking 'Buy Now'.
                  </ul>
                  <div className="shopify-btn">
                    <ShopifyBuyButton productId="9641700032828" />
                  </div>{" "}
                </div>
              </div>
            </div>
            <h5 style={{ paddingTop: "10px", color: "white" }}>
              Enquire Now for Any Doubts! Click the button below to open the
              form and get your queries answered by our team.
            </h5>
            <a
              href="#prebooking"
              className="btn btn-custom"
              style={{ margin: "0" }}
            >
              Enquire Now!
            </a>
          </div>
        </div>
        <div className="container">
          <div id="donation" className="section-title">
            <h2>Why It's for You?</h2>
            <ul>
              {whyForYou ? (
                whyForYou.map((item, index) => (
                  <li key={index} style={{ marginBottom: "10px" }}>
                    <span style={{ fontWeight: "bold" }}>• {item.point}:</span>{" "}
                    {item.description}
                  </li>
                ))
              ) : (
                <p>Loading why it's for you data...</p>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div id="prebooking" className="text-center form section-title">
        <div className="container">
          {/* <h2 className="text-center">Artha</h2> */}
          <p style={{ fontWeight: "bold", fontSize: "22px" }}>
            Use this form to tell your query
          </p>
          <form className="consultation-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-control"
                placeholder="Name"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="form-control"
                required
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                id="question"
                name="question"
                value={formData.question}
                onChange={handleChange}
                placeholder="Question"
                className="form-control"
                required
              />
            </div>

            <button type="submit" className="btn btn-custom">
              Submit
            </button>
          </form>
        </div>
      </div>
      <div id="consultation-page">
        <div id="donation" className="faqs">
          <div id="donationImpact" className="container">
            <h2>Frequently Asked Questions</h2>
            <ul>
              {faqs ? (
                faqs.map((item, index) => (
                  <li key={index} onClick={() => toggleDropdown(index)}>
                    {item.title}
                    {openedDropdown === index && (
                      <div className="dropdown-content">{item.details}</div>
                    )}
                  </li>
                ))
              ) : (
                <p>Loading faqs data...</p>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div id="contact">
        <Contact data={contact} />
      </div>
    </div>
  );
};

export default DharmaConsultancy;


{/* <div className="section  sampoorna-kundali-section">
              <div className="align-items-center">
                <h2>Sampoorna Kundali Query</h2>

                <div className="col-md-6" style={{ padding: "0" }}>
                  <img
                    src={require("./sampoorna kundali.webp")}
                    alt="Sampoorna Kundali Illustration"
                    className="illustration-image"
                  />
                </div>
                <div className="col-md-6 text-block">
                  <h3>Unveil the Secrets of Your Cosmic Blueprint</h3>
                  <p>
                    The Sampoorna Kundali is a comprehensive astrological
                    analysis that delves deep into every aspect of your life.
                    Crafted by the revered Jyotisha Acharyas of Kashi, this
                    Kundali is more than just a birth chart; it’s a complete
                    roadmap of your cosmic journey, designed to provide profound
                    insights into your past, present, and future. Whether you’re
                    looking to understand your true purpose or navigate the
                    complexities of life, the Sampoorna Kundali offers a
                    timeless guide to your spiritual and worldly path.
                  </p>
                </div>
                <ul style={{ color: "#807171" }}>
                  Disclaimer: The actual product may vary slightly in color,
                  design, or appearance from the image shown due to lighting,
                  manufacturing variations, or other factors
                </ul>
                <div>
                  <h4>What Will You Get in This?</h4>
                  <ul className="styled-list">
                    {isExpanded ? (
                      <>
                        <li>
                          A Complete Kundali in booklet format, meticulously
                          created by the Acharyas of Kashi, offering detailed
                          insights into every aspect of your astrological chart.
                        </li>
                        <li>
                          A Customized Spiritual Kit tailored specifically for
                          you, based on your Kundali. This kit will contain
                          items and tools to help you grow spiritually and
                          attract abundance in your life.
                        </li>
                        <li>
                          A One-on-One Call with the Acharya to help you fully
                          understand your Kundali. During this personalized
                          consultation, you’ll gain deeper knowledge about your
                          chart and how to align with cosmic forces guiding you.
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          A Complete Kundali in booklet format, meticulously
                          created by the Acharyas of Kashi, offering detailed
                          insights into every aspect of your astrological chart.
                        </li>
                      </>
                    )}
                  </ul>
                  
                  <p
                    onClick={() => setIsExpanded(!isExpanded)}
                    style={{
                      padding: "0",
                      margin: "0",
                      cursor: "pointer",
                      color: "#ffff00",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    {isExpanded ? "Read Less" : "Read More"}
                  </p>
                  <p
                    className="highlight"
                    style={{ textAlign: "center", paddingTop: "0px" }}
                  >
                    To Enquire about the price and the process Book Now and get
                    a call from our team!
                  </p>
                  <div className="price-section">
                    <span className="current-price">₹0.00</span>
                    <span className="original-price">Regular price</span>
                    <span className="discount">Free Enquiry</span>
                  </div>
                  <div className="tax-info">
                Taxes included. Shipping calculated at checkout.
              </div>
                  <p style={{ textAlign: "center", paddingBottom: "0" }}>
                    Delivery Time: 1 month
                  </p>
                  <div className="shopify-btn">
                    <ShopifyBuyButton productId="9641700393276" />
                  </div>{" "}
                </div>
              </div>
            </div> */}