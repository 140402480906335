import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useAnalytics } from "./Analytics";
import { AuthProvider } from "./components/AuthContext";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Explore } from "./components/explore";
import { Magazine } from "./components/magazine";
import { Donation } from "./components/donation";
import { Membership } from "./components/membership";
import { ShopPreview } from "./components/ShopPreview";
import { Testimonials } from "./components/testimonials";
import { Consultancy } from "./components/Consultancy";
import { Story } from "./components/somarasaStore";
import { Contact } from "./components/contact";
import { ConsultationPage } from "./components/ConsultationPage";
import { DharmaConsultancy } from "./components/dharma";
import { ReligiousCeremonial } from "./components/ReligiousCeremonial";
import { TirthPilgrimage } from "./components/TirthPilgrimage";
import { DonationPage } from "./components/donationPage";
import { Shop } from "./components/shop";
import { Blog } from "./components/blog";
import { BlogDetail } from "./components/blogDetail";
import { CategoryBlogs } from "./components/CategoryBlogs";
import { MagazinePage } from "./components/magazinePage";
import { Account } from "./components/account";
import { Login } from "./components/login";
import { Signup } from "./components/signup";
import { ForgotPassword } from "./components/forgotPassword";
import { About } from "./components/TermsPolicy/aboutUs";
import { TnC } from "./components/TermsPolicy/termsAndConditions";
import { ContactUs } from "./components/TermsPolicy/contactUs";
import { Privacy } from "./components/TermsPolicy/privacyPolicy";
import { Refund } from "./components/TermsPolicy/refundPolicy";
import { Shipping } from "./components/TermsPolicy/shippingPolicy";
import { initializeGtag, trackPageView } from "./utils/gtag";

// import { MembershipPage } from "./components/membershipPage";
// import { VerticalTimeline } from "./verticalTimeline";
// import { VerticalTimelineElement } from "./VerticalTimelineElement";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Bhagwati } from "./components/Bhagwati/bhagwatiMembership";
import { Gallery } from "./components/Bhagwati/gallery";
import PaymentLinkButton from "./components/payment";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const AppContent = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [membershipPageData, setMembershipPageData] = useState({});
  const location = useLocation();

  useEffect(() => {
    setLandingPageData(JsonData);
    setMembershipPageData(JsonData);
  }, []);

  useEffect(() => {
    initializeGtag("G-HKRRDJZTWF"); // Replace with your actual Measurement ID
  }, []);

  useEffect(() => {
    if (typeof window.gtag === "function") {
      trackPageView(location.pathname, document.title);
    } else {
      console.warn("Google Analytics script not loaded yet");
    }
  }, [location]);

  const AnalyticsComponent = () => {
    useAnalytics();
    return null; // This component does not render anything
  };

  return (
    <div className="page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Somarasa | Spiritual Enlightenment & Cultural Exploration</title>
        <meta
          name="description"
          content="Welcome to Somarasa, your gateway to spiritual enlightenment and cultural exploration. Discover spiritual consultations, cultural magazines, handcrafted products, and heritage preservation initiatives. Immerse yourself in the richness of Indian traditions and spirituality."
        />
        <meta
          name="keywords"
          content="Somarasa, spiritual consultations, cultural exploration, Indian traditions, handcrafted products, heritage preservation, spiritual enlightenment, cultural magazines, Hindu spirituality, ancient cultures, support heritage"
        />
        <meta name="author" content="Somarasa Discoveries Private Limited" />
        <link rel="canonical" href="https://somarasa.co.in/" />
        <meta
          property="og:title"
          content="Somarasa | Spiritual Enlightenment & Cultural Exploration"
        />
        <meta
          property="og:description"
          content="Explore Somarasa for spiritual consultations, cultural magazines, and handcrafted products. Join us in preserving Indian heritage and spirituality."
        />
        <meta property="og:url" content="https://somarasa.co.in/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="./src/components/somarasa full logo red background.webp"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Somarasa | Spiritual Enlightenment & Cultural Exploration"
        />
        <meta
          name="twitter:description"
          content="Discover Somarasa's offerings in spiritual consultations, cultural magazines, and handcrafted products. Embrace the richness of Indian culture and spirituality."
        />
        <meta
          name="twitter:image"
          content="./src/components/somarasa full logo red background.webp"
        />
      </Helmet>

      <Navigation />

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header data={landingPageData.Header} />
              <Magazine data={landingPageData.Magazine} />
              <Explore data={landingPageData.Explore} />
              <Story data={landingPageData.Story} />
              {/* <Consultancy data={landingPageData.Consultancy} /> */}
              <Donation data={landingPageData.Donation} />
              {/* <Testimonials data={landingPageData.Testimonials} /> */}
              <Contact data={landingPageData.Contact} />
            </>
          }
        />
        <Route
          path="/contact"
          element={<Contact data={landingPageData.Contact} />}
        />
        <Route
          path="/consultation"
          element={<ConsultationPage data={landingPageData.ConsultationPage} />}
        />
        <Route
          path="/consultation/dharma"
          element={
            <DharmaConsultancy data={landingPageData.DharmaConsultancy} />
          }
        />
        <Route
          path="/consultation/religious-and-ceremonial"
          element={
            <ReligiousCeremonial data={landingPageData.ReligiousCeremonial} />
          }
        />
        <Route
          path="/consultation/tirth-and-pilgrimage"
          element={<TirthPilgrimage data={landingPageData.TirthPilgrimage} />}
        />
        <Route
          path="/supportPage"
          element={<DonationPage data={landingPageData.DonationPage} />}
        />
        <Route path="/shop" element={<Shop data={landingPageData.Shop} />} />
        <Route path="/blog" element={<Blog data={landingPageData.Blog} />} />
        <Route
          path="/:categoryName/:slug"
          element={<BlogDetail data={landingPageData?.Blog} />}
        />

        <Route
          path="/:categoryName"
          element={<CategoryBlogs data={landingPageData.Blog} />}
        />
        <Route
          path="/magazine"
          element={<MagazinePage data={landingPageData.MagazinePage} />}
        />
        {/* <Route path="/blog/:id" element={<BlogDetail />} /> */}
        {/* <Route
              path="/account"
              element={<Account data={landingPageData.Account} />}
            />
            <Route
              path="/login"
              element={<Login data={landingPageData.Login} />}
            />
            <Route
              path="/signup"
              element={<Signup data={landingPageData.Signup} />}
            />
            <Route
              path="/forgotPassword"
              element={<ForgotPassword data={landingPageData.ForgotPassword} />}
            /> */}
        <Route
          path="/termsAndConditions"
          element={<TnC data={landingPageData.TnC} />}
        />
        <Route
          path="/contactUs"
          element={<ContactUs data={landingPageData.ContactUs} />}
        />
        <Route
          path="/Shipping"
          element={<Shipping data={landingPageData.Shipping} />}
        />
        <Route
          path="/Refund"
          element={<Refund data={landingPageData.Refund} />}
        />
        <Route
          path="/privacy"
          element={<Privacy data={landingPageData.Privacy} />}
        />
        <Route
          path="/aboutUs"
          element={<About data={landingPageData.About} />}
        />
        <Route
          path="/bhagwati"
          element={<Bhagwati data={membershipPageData.Bhagwati} />}
        />
        <Route
          path="/gallery"
          element={<Gallery data={membershipPageData.Gallery} />}
        />
        {/* <Route path="/payment" element={<PaymentLinkButton />} />  */}
      </Routes>
    </div>
  );
};

const App = () => (
  <AuthProvider>
    <Router>
      <AppContent />
    </Router>
  </AuthProvider>
);
export default App;
export { Router };
