import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import loadingImage from "./somarasa logo png.webp";
import { Contact } from "./contact";
import ShopifyProduct from "./ShopifyProduct";

export const ReligiousCeremonial = (props) => {
  const [opacity, setOpacity] = useState(1);

  const contactInfo = props.data?.Contact;
  const hero = props.data?.hero;
  const services = props.data?.services || [];
  const pujas = props.data?.pujas || [];
  const totalServices = services.length;
  const faqs = props.data?.faqs;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPujaOpen, setIsPujaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(0);
  const [modalContent, setModalContent] = useState(null);
  const [modalPujaContent, setModalPujaContent] = useState(null);
  const [showTnC, setShowTnC] = useState(false);
  const [showPujaDetails, setShowPujaDetails] = useState(false);

  const toggleTnCModal = () => {
    setShowTnC(!showTnC);
  };

  const handleReadMore = (service) => {
    setIsModalOpen(true);
    setModalContent(service);
  };
  const pujaReadMore = (service) => {
    setIsPujaOpen(true);
    setModalPujaContent(service);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };
  const closeModalPuja = () => {
    setIsPujaOpen(false);
    setModalPujaContent(null);
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const newOpacity = Math.max(1 - scrollY / 500, 0);
    setOpacity(newOpacity);
  };

  const handleImageLoad = () => {
    setLoadedImages((prev) => {
      return prev + 1;
    });
  };

  const handlePujaClick = () => {
    const section = document.getElementById("puja");
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const [openedDropdown, setOpenedDropdown] = useState(null);

  const toggleDropdown = (index) => {
    if (openedDropdown === index) {
      setOpenedDropdown(null); // Close dropdown
    } else {
      setOpenedDropdown(index); // Open dropdown
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (totalServices > 0 && loadedImages === totalServices) {
      setIsLoading(false); // Hide spinner
    } else if (totalServices === 0) {
      setIsLoading(false); // Hide spinner even if no services
    }
  }, [loadedImages, totalServices]);

  return (
    <div id="religious">
      <Helmet>
        <title>Religious and Ceremonial Consultations | Somarasa</title>
        <meta
          name="description"
          content="Explore authentic religious and ceremonial services, including Kundali creation, Pooja arrangements, and spiritual guidance. Book your consultation today!"
        />
        <meta
          name="keywords"
          content="Religious Consultations, Kundali Creation, Pooja Services, Spiritual Guidance, Kashi Rituals"
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "How do I book a consultation?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "You can book a consultation through our online form or by contacting us directly."
            }
          },
          {
            "@type": "Question",
            "name": "Are online pooja options available?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, we offer online pooja services for those unable to visit Kashi."
            }
          }
        ]
      }
    `}
        </script>
      </Helmet>

      {/* Hero Section */}
      
          <section className="hero" style={{ opacity }}>
            {hero ? (
              <>
                <div className="hero-content">
                  <h1>{hero.title}</h1>
                  <p>{hero.description}</p>
                  <div id="consultancy">
                    <a
                      href={hero.buttonLink}
                      className="btn btn-custom btn-lg page-scroll"
                    >
                      {hero.buttonText}
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <p>Loading hero content...</p>
            )}
          </section>
        
      {/* Services Section */}

      <section id="services" className="services">
        <div className="container">
          <h2>Our Offerings</h2>

          <div className="services-grid">
            {services.map((service) => (
              <div key={service.id} className="service-card">
                <div className="service-image-wrapper">
                  <img
                    src={service.image}
                    alt={service.title}
                    className="service-image"
                    onLoad={handleImageLoad}
                  />
                  <div className="service-overlay">
                    {/* <h3>{service.subtitle}</h3> */}
                    <p>{service.description}</p>
                    <button
                      className="read-more-btn"
                      onClick={() => handleReadMore(service)}
                    >
                      Read More
                    </button>
                  </div>
                </div>
                {/* Shopify Button Below the Card */}
                <div className="service-button">
                  {service.id === "pooja-sanskaras" ? (
                    <div>
                      <h3
                        style={{
                          fontSize: "18px",
                          color: "#fff",
                          marginBottom: "15px",
                          marginTop: "0",
                          padding: "0",
                        }}
                      >
                        Pooja and Sanskaras
                      </h3>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#d0acac",
                          fontWeight: "bold",
                          marginBottom: "15px",
                          marginTop: "0",
                          padding: "0",
                        }}
                      >
                        Starting Price: Rs. 12,001.00
                      </p>
                    </div>
                  ) : (
                    <ShopifyProduct productId={service.productId} />
                  )}
                </div>
                <div id="consultancy" style={{ padding: "0" }}>
                  {service.id === "pooja-sanskaras" ? (
                    <button
                      className="btn btn-custom"
                      onClick={handlePujaClick}
                    >
                      Book Now
                    </button>
                  ) : (
                    <a
                      href={service.productLink}
                      className="btn btn-custom"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Book Now
                    </a>
                  )}
                </div>
                {/* <div id="consultancy" style={{ padding: "0" }}>
                  <a
                    href={service.productLink}
                    className="btn btn-custom"
                    style={{ margin: "0" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book Now
                  </a>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </section>

      {isModalOpen && modalContent && (
        <div className="modal-overlay">
          <div className="modal">
            <div
              className="modal-background"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${modalContent.image
                  .replace(/ /g, "%20")
                  .trim()})`,
              }}
            ></div>
            <div className="modal-content">
              <button className="close-btn" onClick={closeModal}>
                &times;
              </button>
              <div className="modal-text">
                <h3>{modalContent.title}</h3>
                <p>{modalContent.longDescription?.paragraph1}</p>
                <p>{modalContent.longDescription?.paragraph2}</p>
                <p>{modalContent.longDescription?.paragraph3}</p>
                <div id="consultancy" style={{ padding: "0" }}>
                  <a
                    href={modalContent.productLink}
                    className="btn btn-custom"
                    style={{ margin: "0" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book Now
                  </a>
                </div>
                <div className="modal-button">
                  <ShopifyProduct productId={modalContent.productId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <section id="puja" className="puja services">
        <div className="container">
          <h2>Pooja and Sanskaras List</h2>
          <div className="services-grid">
            {pujas.map((puja) => (
              <div key={puja.id} className="service-card">
                <div className="service-image-wrapper">
                  <img
                    src={puja.image}
                    alt={puja.title}
                    className="service-image"
                    onLoad={handleImageLoad}
                  />
                  <div className="service-overlay">
                    {/* <p>{puja.description}</p> */}
                    <button
                      className="read-more-btn"
                      onClick={() => pujaReadMore(puja)}
                    >
                      Read More
                    </button>
                  </div>
                </div>

                <div className="service-button">
                  <ShopifyProduct productId={puja.productId} />
                </div>
                <div id="consultancy" style={{ padding: "0" }}>
                  <a
                    href={puja.productLink}
                    className="btn btn-custom"
                    style={{ margin: "0" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book Now
                  </a>
                </div>
                <div>
                  <a
                    href={puja.blogLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blog-link"
                  >
                    Read Our Detailed Blog
                  </a>
                  <button onClick={toggleTnCModal} className="btn btn-link">
                    View Terms and Conditions
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {showTnC && (
        <div className="modal-overlay">
          <div className="modal">
            <div
              className="modal-content"
              style={{ backgroundColor: "#6e0507" }}
            >
              <h3>Terms and Conditions</h3>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                {props.data?.tncForPuja.map((item, index) => (
                  <li key={index} style={{ marginBottom: "10px" }}>
                    {item}
                  </li>
                ))}
              </ul>
              <button onClick={toggleTnCModal} className="close-btn">
                &times;
              </button>
            </div>
          </div>
        </div>
      )}

      {isPujaOpen && modalPujaContent && (
        <div className="modal-overlay">
          <div className="modal">
            <div
              className="modal-background"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${modalPujaContent.image
                  .replace(/ /g, "%20")
                  .trim()})`,
              }}
            ></div>
            <div className="modal-content">
              <button className="close-btn" onClick={closeModalPuja}>
                &times;
              </button>
              <div className="modal-text">
                <h3 style={{ color: "yellow" }}>{modalPujaContent.title}</h3>
                <p>
                  {modalPujaContent.description}{" "}
                  <a
                    href={modalPujaContent.blogLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blog-link"
                    style={{ color: "pink", textDecoration: "underline" }}
                  >
                    Read in Detail About This Puja
                  </a>
                </p>

                <h4 style={{ color: "orange" }}>What You Will Receive</h4>
                {modalPujaContent.whatYouWillReceive.map((item) => (
                  <p key={item.label}>
                    <span style={{ color: "orange" }}>{item.label}:</span>{" "}
                    {item.value}
                  </p>
                ))}
                <h4 style={{ color: "orange" }}>Why Book This Puja</h4>
                <p>{modalPujaContent.whyBookThisPuja}</p>

                <div className="modal-button">
                  <ShopifyProduct productId={modalPujaContent.productId} />
                </div>
                <div id="consultancy" style={{ padding: "0" }}>
                  <a
                    href={modalPujaContent.productLink}
                    className="btn btn-custom"
                    style={{ margin: "0" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Why Choose Us Section */}
      {/* <section className="why-choose-us">
        <div className="container">
          <h2>Why Choose Us?</h2>
          <ul>
            <li>
              <strong>Trusted Experts:</strong> Decades of experience in
              spiritual and ceremonial services.
            </li>
            <li>
              <strong>Authenticity:</strong> Rooted in ancient Vedic knowledge
              and traditions.
            </li>
            <li>
              <strong>Comprehensive Services:</strong> From online consultations
              to full ceremony planning.
            </li>
          </ul>
        </div>
      </section> */}

      {/* Testimonials Section */}
      {/* <section className="testimonials">
        <div className="container">
          <h2>What Our Customers Say</h2>
          <div className="testimonial">
            <p>
              "The guidance I received was transformative. Guru Prabhu Ji's
              insights have brought clarity to my life."
            </p>
            <p className="author">- Priya Sharma</p>
          </div>
          <div className="testimonial">
            <p>
              "Planning a ceremony in Kashi was seamless. The arrangements
              exceeded our expectations."
            </p>
            <p className="author">- Rajesh Verma</p>
          </div>
        </div>
      </section> */}

      {/* FAQ Section */}
      <div id="donation" className="faqs">
        <div id="donationImpact" className="container">
          <h2>Frequently Asked Questions</h2>
          <ul>
            {faqs ? (
              faqs.map((item, index) => (
                <li key={index} onClick={() => toggleDropdown(index)}>
                  {item.title}
                  {openedDropdown === index && (
                    <div className="dropdown-content">{item.details}</div>
                  )}
                </li>
              ))
            ) : (
              <p>Loading faqs data...</p>
            )}
          </ul>
        </div>
      </div>

      <Contact data={contactInfo} />
    </div>
  );
};

export default ReligiousCeremonial;
