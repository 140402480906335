// src/utils/gtag.js

// Initialize Google Analytics
export const initializeGtag = (measurementId) => {
  if (!measurementId) {
    console.warn("Google Analytics Measurement ID is missing");
    return;
  }

  // Check if gtag.js is already loaded
  if (
    document.querySelector(
      `script[src*="https://www.googletagmanager.com/gtag/js?id=${measurementId}"]`
    )
  ) {
    console.log("Google Analytics script already loaded");
    return;
  }

  // Add the gtag.js script dynamically
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  script.async = true;
  document.head.appendChild(script);

  // Initialize gtag
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", measurementId);

  console.log(
    "Google Analytics initialized with Measurement ID:"
  );
};

// Track page views (for SPAs)
export const trackPageView = (path, title) => {
  if (typeof window === "undefined" || !window.gtag) {
    console.warn(
      "gtag is not initialized or not available in this environment"
    );
    return;
  }
  window.gtag("event", "page_view", {
    page_path: path,
    page_title: title || document.title,
  });
  console.log(`Tracked page view: ${path}`);
};

// Track custom events
export const trackEvent = (category, action, label, value) => {
  if (!window.gtag) {
    console.warn("gtag is not initialized yet");
    return;
  }
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
  });
  console.log(
    `Tracked event: ${action}, Category: ${category}, Label: ${label}`
  );
};

// Consent-based loading (Optional for GDPR/CCPA compliance)
export const loadGtagWithConsent = (measurementId, consentGiven) => {
  if (consentGiven) {
    initializeGtag(measurementId);
  } else {
    console.log("User did not give consent for tracking");
  }
};
